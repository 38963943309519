/* eslint-disable max-len */
import * as React from 'react'
import { useTheme } from '@mui/material/styles'

type NavLogoProps = {
  href?: string
  height?: string | number
  width?: string | number
}

export const NavLogo = ({
  href,
  height,
  width,
}: NavLogoProps) => (
  <a
    href={href || '/'}
    style={{ display: 'flex' }}
  >
    <svg
      version='1.1'
      viewBox='0 0 324.62 115.73'
      xmlns='http://www.w3.org/2000/svg'
      height={height}
      width={width}
    >
      <rect
        width='324.62'
        height='115.73'
        ry='10.561'
        fill={useTheme().palette.text.primary}
      />
      <g fill={useTheme().palette.background.default}>
        <path d='M32.02,45.65c-.12,.15-.15,.17-.15,.19,.07,8.82-.21,17.65,.16,26.47,.04,.88,.23,1.74,.7,2.5,.83,1.35,2.22,1.41,3.58,1.55,.37,.04,.93-.18,1.04,.28,.14,.57,.07,1.2,.02,1.79-.02,.24-.33,.21-.54,.21-4.93,0-9.86,0-14.8,.01-.46,0-.53-.17-.55-.58-.06-1.62-.07-1.61,1.53-1.73,.51-.04,1.03-.11,1.52-.22,1.85-.43,2.74-1.56,2.93-3.74,.1-1.23,.17-2.47,.17-3.71,.01-7.25,.02-14.51,0-21.76,0-1.08-.06-2.18-.24-3.25-.44-2.59-1.48-3.49-4.1-3.74-.41-.04-1.05,.13-1.16-.23-.18-.58-.08-1.25-.05-1.88,.01-.32,.32-.21,.51-.21,4.63,0,9.26,0,13.89-.02,.55,0,.72,.34,.96,.68,4.64,6.65,9.28,13.3,13.93,19.96,2.17,3.12,4.34,6.23,6.53,9.34,.16,.23,.25,.54,.67,.69v-.91c0-6.95,0-13.9,0-20.85,0-1.05-.08-2.11-.2-3.16-.23-1.95-1.12-2.89-3.03-3.28-.27-.05-.54-.09-.81-.12-1.49-.11-1.73-.45-1.46-1.94,.07-.39,.28-.39,.57-.39,4.93,0,9.86,0,14.8,0,.4,0,.5,.15,.52,.52,.12,1.69,.13,1.69-1.58,1.81-3.03,.2-4.21,1.34-4.42,4.39-.1,1.41-.11,2.83-.11,4.25,0,9.88-.02,19.77,.02,29.65,0,.81-.2,1.02-1,.99-1.84-.06-3.69-.04-5.54,0-.5,0-.81-.14-1.11-.57-6.87-9.8-13.75-19.6-20.64-29.39-.83-1.18-1.66-2.35-2.52-3.58Z' />
        <path d='M139.08,73.31c-.33,.5-.51,.77-.69,1.04-2.5,3.73-6.82,5.51-11.22,4.55-2.56-.56-4.35-2.08-5.06-4.65-.73-2.66,.1-4.88,2.13-6.67,1.7-1.5,3.7-2.48,5.76-3.37,2.54-1.1,5.08-2.2,7.63-3.27,.54-.23,.78-.5,.75-1.13-.06-1.39,0-2.78-.33-4.15-.7-2.91-3.18-3.47-5.42-2.92-.75,.19-1.22,.76-1.6,1.39-.43,.7-.69,1.47-1.01,2.22-.48,1.15-1.19,2.12-2.5,2.42-1.4,.32-2.73,.27-3.7-.99-.88-1.15-.87-3.22-.02-4.55,.79-1.23,1.98-1.99,3.32-2.39,4.41-1.32,8.89-1.47,13.32-.19,3.18,.92,4.94,3.4,5.04,7.07,.12,4.32,.06,8.64,.08,12.96,0,1.09,.04,2.18,.34,3.23,.4,1.39,1.33,1.78,2.66,1.18,.24-.11,.54-.64,.78-.14,.25,.53,1.01,.99,.44,1.74-1.34,1.75-3.21,2.43-5.32,2.45-3.44,.02-5.12-1.64-5.33-5.1,0-.14-.02-.29-.05-.73Zm-.71-6.72c0-.91-.02-1.67,0-2.42,.02-.54-.13-.62-.61-.39-2.14,1.04-4.31,2.05-6.19,3.54-.74,.59-1.41,1.24-1.81,2.1-.9,1.92,.17,4.29,2.06,4.59,2.42,.39,5.07-1.1,6.07-3.33,.62-1.39,.43-2.83,.48-4.11Z' />
        <path d='M241.15,73.54c-.32,.46-.63,.94-.96,1.39-2.59,3.48-7.32,5.06-11.41,3.82-4.79-1.46-6.27-7.13-2.79-10.72,1.52-1.57,3.42-2.57,5.39-3.45,2.81-1.25,5.63-2.45,8.46-3.65,.55-.23,.79-.5,.76-1.13-.06-1.36,.01-2.72-.3-4.06-.68-2.96-3.07-3.53-5.36-3.04-.79,.17-1.28,.77-1.69,1.42-.43,.69-.69,1.47-1,2.22-.49,1.15-1.19,2.12-2.5,2.42-1.4,.32-2.73,.27-3.7-.99-.86-1.11-.88-3.17-.07-4.47,.7-1.13,1.76-1.81,2.95-2.3,1.41-.59,2.89-.87,4.4-1,2.63-.23,5.25-.27,7.86,.24,4.17,.82,6.41,3.3,6.53,7.58,.12,4.32,.05,8.64,.07,12.96,0,1.06,.06,2.12,.35,3.14,.4,1.39,1.32,1.78,2.65,1.17,.24-.11,.54-.65,.78-.14,.24,.53,1.03,.97,.44,1.74-1.34,1.76-3.21,2.44-5.31,2.46-3.42,.03-5.08-1.58-5.34-5-.01-.2,.07-.43-.2-.61Zm-.57-6.85c0-.96,0-1.74,0-2.53,0-.41-.03-.65-.54-.41-2.26,1.07-4.5,2.18-6.47,3.75-.66,.53-1.24,1.15-1.6,1.94-.89,1.93,.18,4.29,2.07,4.59,2.45,.39,5.11-1.13,6.1-3.42,.58-1.33,.38-2.72,.43-3.92Z' />
        <path d='M87.96,62.7c-2.75,0-5.5,.02-8.26-.01-.6,0-.86,.14-.89,.78-.13,2.44,.09,4.83,1.05,7.1,1.8,4.26,5.66,6,10.19,4.62,1.97-.6,3.69-1.64,5.23-2.98,.52-.45,.76-.33,1.12,.14,1.07,1.37,1.06,1.33-.22,2.58-5.94,5.78-15.8,5.25-20.79,.95-3.31-2.85-4.74-6.53-4.82-10.81-.13-7.55,4.52-13.4,11.91-14.91,2.82-.57,5.66-.53,8.41,.43,4.18,1.45,6.33,4.53,6.86,8.83,.07,.54,.09,1.08,.12,1.63,.08,1.36-.11,1.62-1.48,1.64-2.81,.03-5.63,0-8.44,0h0Zm-3.62-3.06c1.44-.04,3.13,.09,4.82-.12,.54-.07,1.06-.21,1.13-.83,.37-3.24-2.06-6.22-5.44-5.59-2.54,.47-5.26,3.57-5.42,6.15-.03,.46,.23,.4,.5,.4,1.39,0,2.78,0,4.41,0Z' />
        <path d='M254.88,64.11c0-7.87,5.87-13.93,14.25-14.24,2.65-.1,5.26,.12,7.75,1.11,1.45,.58,2.74,1.4,3.7,2.67,.84,1.11,1.07,2.36,.71,3.69-.55,2.06-3.13,2.88-5.15,1.67-.8-.48-1.35-1.19-1.87-1.95-.49-.72-.96-1.46-1.47-2.17-1.64-2.24-4.29-2.55-6.46-.77-1.42,1.17-2.16,2.75-2.57,4.48-.83,3.46-.87,6.96-.01,10.41,1.31,5.26,5.25,7.38,10.62,5.81,1.67-.49,3.21-1.25,4.6-2.29,.44-.33,.68-.41,1.04,.14,1.07,1.62,1.13,1.63-.42,2.85-3.59,2.83-7.72,3.73-12.19,3.25-4.2-.45-7.69-2.23-10.13-5.77-1.74-2.52-2.37-5.38-2.4-8.91Z' />
        <path d='M208.74,56.11c1.01-1.79,2.05-3.22,3.49-4.31,2.16-1.64,4.54-2.41,7.22-1.7,2.12,.56,3.08,2.21,2.69,4.36-.37,2.01-1.91,3.2-3.95,3.06-.76-.05-1.38-.41-1.98-.82-.37-.26-.73-.54-1.1-.8-1.06-.75-1.91-.62-2.75,.4-1.21,1.47-1.68,3.25-2.07,5.05-.89,4.06-.85,8.16-.48,12.26,.15,1.68,.85,2.31,2.48,2.64,.81,.16,1.63,.15,2.44,.15,.39,0,.5,.13,.52,.52,.11,1.74,.12,1.74-1.56,1.74-5.14,0-10.29,0-15.43,0-.58,0-1.07-.02-.91-.79,.12-.58-.51-1.6,.85-1.52,.36,.02,.72-.08,1.08-.14,1.87-.29,2.59-1.09,2.71-3,.19-3.05,.1-6.1,.1-9.15,0-2.14,0-4.28-.26-6.41-.34-2.75-1.21-3.61-3.96-3.87-.43-.04-.91,0-.74-.65,.13-.51-.47-1.26,.65-1.45,3.12-.51,6.23-1.12,9.34-1.73,.57-.11,.81-.05,.88,.6,.2,1.77,.47,3.53,.75,5.57Z' />
        <path d='M153.49,73.79c0-1.42,.02-2.84-.01-4.26-.01-.51,.13-.65,.65-.66,1.61,0,1.61-.03,2.09,1.51,.51,1.63,1.19,3.16,2.48,4.35,1.6,1.47,4.51,1.95,6.47,1.05,1.54-.7,2.12-2.22,1.48-3.87-.39-.99-1.11-1.72-1.9-2.38-1.26-1.05-2.71-1.8-4.13-2.6-1.82-1.03-3.58-2.15-4.96-3.76-2.1-2.45-2.54-5.25-1.35-8.21,1.2-3,3.54-4.66,6.74-5.09,2.51-.33,4.88,.14,7.06,1.43,.39,.23,.53,.14,.77-.18,.23-.3,.35-.71,.78-.83,.95-.25,1.29,0,1.29,.99,0,2.15-.02,4.29,.01,6.44,0,.55-.13,.67-.69,.72-1.06,.09-1.73-.17-2.06-1.28-.27-.89-.82-1.67-1.37-2.44-1.09-1.51-2.56-2.15-4.4-1.95-1.95,.21-3.03,2.13-2.27,4.06,.46,1.14,1.35,1.91,2.3,2.62,1.56,1.15,3.34,1.93,4.97,2.96,1.33,.84,2.63,1.72,3.74,2.85,4.29,4.39,2.84,11.11-2.95,13.13-3.95,1.37-7.87,1.13-11.44-1.3-.52-.35-.6-.03-.78,.27-.43,.73-.77,1.5-1.9,1.28-.44-.09-.62-.15-.61-.63,.03-1.42,.01-2.84,0-4.26Z' />
        <path d='M118.67,73.83c.07,.06,.13,.08,.16,.13,1.13,1.52,1.07,2.15-.38,3.46-1.3,1.17-2.88,1.61-4.58,1.71-1.69,.09-3.37,.05-4.99-.57-2.53-.97-3.92-3.03-3.92-5.72,0-6.22-.03-12.44-.02-18.66,0-.62-.15-.86-.8-.81-.78,.06-1.58-.04-2.36,.03-.67,.06-.79-.2-.77-.81,.03-1.58,0-1.58,1.51-2.15,3.02-1.16,5.38-3.09,6.85-6,.24-.48,.54-.53,.99-.53,2.08,0,2.08-.01,2.08,2.03,0,1.27,.02,2.54-.01,3.81-.01,.51,.13,.67,.66,.66,1.6-.03,3.21,0,4.81-.02,.51,0,.65,.14,.66,.66,.04,2.34,.06,2.34-2.24,2.34-1.09,0-2.18,.02-3.27-.01-.48-.01-.62,.13-.62,.61,.03,5.8,.02,11.6,.06,17.41,0,.68,.15,1.38,.33,2.05,.63,2.36,3.27,3.06,5.04,1.35,.3-.29,.54-.63,.81-.95Z' />
        <path d='M180.62,63.6c0-3.14-.01-6.28,.01-9.42,0-.61-.12-.89-.79-.82-.48,.05-.97,.03-1.45,0-.53-.03-1.38,.24-1.53-.12-.26-.62-.11-1.41-.11-2.13,0-.22,.16-.28,.36-.33,3.62-1.07,6.4-3.18,8.1-6.6,.12-.23,.25-.27,.47-.29q2.46-.25,2.46,2.2c0,1.18,.03,2.36-.01,3.53-.02,.58,.13,.79,.75,.77,1.57-.05,3.14,0,4.72-.03,.53-.01,.65,.16,.66,.67,.05,2.33,.06,2.33-2.24,2.33-1.09,0-2.18,.02-3.27-.01-.48-.01-.61,.15-.61,.62,.03,5.83,.03,11.66,.07,17.49,0,.6,.13,1.2,.26,1.79,.25,1.12,.76,2.1,1.99,2.33,1.32,.25,2.59,.03,3.4-1.15,.51-.74,.66-.41,1.09,.05,.68,.72,.48,1.31,0,1.99-1.46,2.07-3.61,2.6-5.95,2.68-1.39,.05-2.77-.03-4.1-.48-2.76-.92-4.22-2.99-4.21-5.94,0-3.04,0-6.09,0-9.13-.02,0-.05,0-.07,0Z' />
        <path d='M301.8,73.76c.32,.44,.56,.83,.86,1.16,.3,.33,.25,.62,.07,.97-.86,1.64-2.26,2.57-4.01,2.98-1.91,.44-3.85,.44-5.76-.02-3.19-.78-4.89-2.96-4.9-6.29-.02-6.04-.03-12.09,.02-18.13,0-.86-.18-1.22-1.07-1.07-.32,.05-.66,0-1,0-1.92,0-1.9,0-1.83-1.92,.02-.45,.19-.59,.6-.73,3.45-1.11,6.17-3.13,7.8-6.44,.22-.45,.54-.38,.9-.39q2.06-.02,2.06,2.02c0,1.27,.02,2.54-.01,3.81-.01,.53,.17,.67,.67,.66,1.57-.03,3.15,.01,4.72-.02,.55-.01,.85,.08,.76,.72-.1,.74,.32,1.73-.17,2.16-.41,.36-1.38,.11-2.1,.12-1.09,0-2.18,.02-3.27,0-.48-.01-.62,.15-.61,.62,.08,5.92-.14,11.84,.13,17.76,.02,.45,.1,.9,.19,1.34,.25,1.18,.73,2.25,2.04,2.5,1.39,.27,2.65-.07,3.53-1.3,.1-.15,.21-.29,.38-.54Z' />
      </g>
    </svg>
  </a>
)

export default NavLogo
