import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import NavBar from '../components/nav_bar'
import SeoHeaders from '../components/seo_headers'

const IndexPage = () => (
  <React.Fragment>
    <SeoHeaders description='Dinero internacional a tasas preferenciales' />
    <NavBar>
      <Button
        href='/sign_up/'
        variant='contained'
        size='large'
        sx={{ borderRadius: 0, py: 2, px: 3 }}
      >
        Comenzar
      </Button>
    </NavBar>
    <StaticImage
      src='../images/background.jpg'
      alt='Montañas'
      layout='fullWidth'
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
      }}
      quality={90}
    />
    <Container
      sx={(theme) => ({
        position: 'absolute',
        top: '80px',
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 2,
        px: 2,
        height: '70%',
        [theme.breakpoints.up('lg')]: {
          width: '50%',
        },
        [theme.breakpoints.up('xl')]: {
          maxWidth: theme.breakpoints.values.md,
        },
      })}
    >
      <Typography
        variant='h2'
        component='h1'
        fontWeight={500}
        fontFamily='"Playfair Display", Garamond, serif'
        letterSpacing='-0.01em'
        lineHeight='1.1'
        color='grey.800'
      >
        Liderando el Camino con Software y Plataformas SaaS
      </Typography>
      <Typography
        variant='h6'
        component='h2'
        fontWeight={400}
        color='grey.700'
      >
        Nuestra pasión por la tecnología: creamos herramientas digitales que
        facilitan la colaboración y el crecimiento empresarial.
      </Typography>
    </Container>
  </React.Fragment>
)

export default IndexPage
