import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import Stack from '@mui/material/Stack'
import MenuIcon from '@mui/icons-material/Menu'
import { styled } from '@mui/material/styles'
import NavLogo from './nav_logo'

const StyledProgress = styled(LinearProgress)(() => ({
  '& .MuiLinearProgress-bar': {
    background: 'var(--color-mainTitleGradient)',
  },
}))

type MenuButtonProps = {
  onClick: () => void
}

const MenuButton = ({ onClick }: MenuButtonProps) => (
  <IconButton
    aria-label='open sidebar'
    onClick={onClick}
    sx={{
      display: {
        xs: 'inline-flex',
        sm: 'none',
      },
    }}
  >
    <MenuIcon />
  </IconButton>
)

type FixedComponentsProps = {
  onMenuButtonClick: (() => void) | undefined
  rootPath?: string
}

const FixedComponents = ({ onMenuButtonClick, rootPath }: FixedComponentsProps) => (
  <Stack
    direction='row'
    alignItems='center'
    justifyContent='space-between'
    sx={{ flexGrow: 1, px: 1 }}
  >
    {onMenuButtonClick && (
      <MenuButton onClick={onMenuButtonClick} />
    )}
    <NavLogo
      href={rootPath}
      height='48px'
    />
  </Stack>
)

type NavBarProps = {
  onMenuButtonClick?: () => void
  showProgress?: boolean
  progressValue?: number
  rootPath?: string
  children: React.ReactNode
}

export const NavBar = ({
  onMenuButtonClick = undefined,
  showProgress = false,
  progressValue = undefined,
  rootPath,
  children,
}: NavBarProps) => (
  <AppBar
    color='inherit'
    position='sticky'
    elevation={1}
    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
  >
    <Stack
      direction='row'
      alignItems='center'
    >
      <FixedComponents
        onMenuButtonClick={onMenuButtonClick}
        rootPath={rootPath}
      />
      {children}
    </Stack>
    {showProgress && (
      <StyledProgress
        variant={(typeof progressValue === 'undefined') ? 'indeterminate' : 'determinate'}
        value={progressValue}
      />
    )}
  </AppBar>
)

export default NavBar
